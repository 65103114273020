<template>
  <div class="frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="p4a__home">
      <b-row class="p4a__appointment-bg__adjust">
        <b-col lg="12">
          <div class="p4a__appointment-left">
            <div>
              <router-link
                :to="'/appointments'"
                class="brand-logo"
              >
                <img :src="logoImg">
              </router-link>
            </div>
            <div>
              <h2 class="p4a__appointment-left__title">
                Thank you for your payment.
              </h2>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol,
} from 'bootstrap-vue'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BCol,

    Loader,
  },
  data() {
    return {
      events: [],
      activityTypes: [],
      logoImg: require('@/assets/images/frontend/logo-orange-yellow.png'),
      bannerImg: require('@/assets/images/frontend/banner_image.png'),
      activity1: require('@/assets/images/frontend/activity1.png'),
      activity4: require('@/assets/images/frontend/activity4.png'),
      serviceBooking: false,
      appointmentBooking: true,
      showServiceBooking: false,
    }
  },
  beforeMount() {
    document.body.classList.add('bg-white')
  },
  destroyed() {
    document.body.classList.remove('bg-white')
  },

  methods: {
  },
}
</script>

  <style lang="scss" scoped>

  .frontend .p4a__home{
    overflow: hidden;
  }
  @media only screen and (min-width: 768px) and (max-width:991px) {
    .gap-class {
      padding: 0 15px;
    }
  }

  @media only screen and (min-width: 768px) {
    .gap-class {
      gap: 30px;
      padding: 0 15px;
    }

    .page-content .activities .card img {
      width: 100%;
      height: 160px;
      border-radius: 8px 8px 0 0 !important;
      object-fit: cover;
    }
  }

  .page-content .btn-light:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #fffdfd;
  }

  .page-content .btn-light {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }

  .page-content .btn-light:hover {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }

  .page-content p.service-title {
    padding: 0;
    margin: 0 0 15px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  div#app.semi-dark-layout {
    background: unset !important;
  }

  .frontend {
    .page-content {
      background: #F5F0DF !important;
      background-size: 100% auto !important;

      .activities {
        &:before {
          background: unset;
        }

        &:after {
          background: unset;
        }
      }
    }
  }

  .page-content .activities {
    top: 50%;
    left: 0;
    transform: translate(0%, 50%);
  }

  html body {
      background-color: #fff !important;
  }
  </style>
